<script setup lang="ts">
import { ref } from 'vue'
import ship from 'assets/imgs/ship.png'
import type {
  VoixLinkFieldInterface,
  VoixMediaFieldInterface,
  VoixNumberFieldInterface,
  VoixSelectFieldInterface,
  VoixTextFieldInterface,
  VoixWysiwygFieldInterface,
} from '@voix/types'
import Gallery from '../components/Gallery.vue'
import { defineSlice } from '#imports'

const props = defineProps<{
  fields: {
    subTitle: VoixTextFieldInterface
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    linkText: VoixLinkFieldInterface
    number: VoixNumberFieldInterface
    titleSize: VoixSelectFieldInterface
    media1: VoixMediaFieldInterface
    media2: VoixMediaFieldInterface
    media3: VoixMediaFieldInterface
  }
}>()

defineSlice({
  name: {
    label: 'Paragraph with Gallery',
    group: 'Slices',
  },
  description: 'A paragraph with a gallery',
  fields: {
    subTitle: {
      type: 'text',
      label: 'Subtitle',
      enabled: true,
    },
    title: {
      type: 'text',
      label: 'Title',
    },
    copy: {
      type: 'wysiwyg',
      label: 'Copy',
    },
    linkText: {
      type: 'link',
      label: 'Link',
      enabled: true,
    },
    number: {
      type: 'number',
      label: 'Number of images',
      default: 3,
      min: 1,
      max: 3,
    },
    titleSize: {
      type: 'select',
      label: 'Title Size',
      default: 'md',
      options: {
        sm: 'Small',
        md: 'Medium',
        lg: 'Large',
        xl: 'Extra Large',
      },
    },
    media1: {
      type: 'media',
      label: 'Media 1',
      breakpoints: {
        default: {
          width: 600,
        },
        xl: {
          width: 1200,
        },
      },
    },
    media2: {
      type: 'media',
      label: 'Media 2',
      breakpoints: {
        default: {
          width: 600,
        },
        xl: {
          width: 1200,
        },
      },
    },
    media3: {
      type: 'media',
      label: 'Media 3',
      breakpoints: {
        default: {
          width: 600,
        },
        xl: {
          width: 1200,
        },
      },
    },
  },
})

const showGallery = ref(false)
const currentImage = ref(0)

function openGallery(imageIndex: number) {
  currentImage.value = imageIndex
  showGallery.value = true
}
</script>

<template>
  <div class="container mx-auto py-24">
    <div class="lg:grid lg:grid-cols-12 text-stone-500">
      <div class="col-span-5 flex flex-col items-start justify-start lg:pl-16">
        <div class="text-sm uppercase font-bold">
          {{ fields.subTitle.value }}
        </div>
        <div
          class="uppercase font-extrabold text-brand-orange" :class="{
            'text-lg': fields.titleSize.value === 'sm',
            'text-xl': fields.titleSize.value === 'md',
            'text-2xl': fields.titleSize.value === 'lg',
            'text-3xl': fields.titleSize.value === 'xl',
          }"
        >
          {{ fields.title.value }}
        </div>
        <div class="border-t border-brand-orange mt-2 pt-4 prose prose-lg prose-slate text-stone-500">
          <!-- testing that /public and /assets images work -->
          <div class="flex justify-between w-4/5">
            <img class="my-0" width="30px" height="30px" src="/map.png">
            <div class="my-0" style="width:30px;height:30px;" />
            <img class="my-0" width="30px" height="30px" src="~/assets/imgs/plane.png">
            <div
              class="my-0" style="width:30px;height:30px;background-size:cover;"
              :style="{ backgroundImage: `url(${ship})` }"
            />
          </div>

          <VoixWysiwyg :field="fields.copy" />
        </div>

        <VoixLink :field="fields.linkText" class="mt-4 rounded-full px-4 py-3 bg-brand-navy text-stone-200" />
      </div>
      <div class="mt-12 col-span-6 col-start-7">
        <div
          class="grid grid-cols-8 gap-3"
        >
          <div class="relative rounded-bl-4xl overflow-hidden col-span-3 h-64 bg-slate-800">
            <VoixMedia :field="props.fields.media1" class="object-cover w-full h-full" breakpoint="default" />

            <button
              class="absolute z-10 bottom-0 right-0 m-4 text-white"
              @click="openGallery(0)"
            >
              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8zm6-4a4 4 0 100 8 4 4 0 000-8z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div
            class="relative rounded-r-full rounded-tl-full overflow-hidden col-span-5 h-64"
          >
            <VoixMedia :field="props.fields.media2" class="object-cover w-full h-full" breakpoint="default" />

            <button
              class="absolute z-10 bottom-0 original-layout:right-0 m-4 text-white"
              @click="openGallery(1)"
            >
              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8zm6-4a4 4 0 100 8 4 4 0 000-8z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div
            class="relative rounded-tr-full rounded-b-full overflow-hidden col-span-5 col-start-4 h-64"
          >
            <VoixMedia :field="props.fields.media3" class="object-cover w-full h-full" breakpoint="default" />

            <div
              class="absolute z-10 top-0 original-layout:bottom-0 left-0 original-layout:right-0 flex justify-center"
            >
              <button class="m-4 text-white" @click="openGallery(2)">
                <svg
                  class="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8zm6-4a4 4 0 100 8 4 4 0 000-8z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Transition
      enter-active-class="transition-opacity duration-300"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-300"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <Gallery
        v-if="showGallery"
        :images="[fields.media1, fields.media2, fields.media3]"
        :current-image="currentImage"
        @close="showGallery = false"
      />
    </Transition>
  </div>
</template>
