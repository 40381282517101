<script setup lang="ts">
import { ref } from 'vue'

// Import Swiper Vue.js components
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { Swiper as SwiperType } from 'swiper'
import type { Ref } from 'vue'
import type { VoixMediaFieldInterface } from '@voix/types'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

// defineProps({
//   images: {
//     type: Array,
//     default: () => [],
//   },
//   currentImage: {
//     type: Number,
//     default: 0,
//   },
// })
defineProps<{
  images: VoixMediaFieldInterface[]
  currentImage: number
}>()

defineEmits(['close'])

const thumbsSwiper: Ref<SwiperType | null> = ref(null)

function setThumbsSwiper(swiper: SwiperType) {
  thumbsSwiper.value = swiper
}
</script>

<template>
  <div
    class="fixed inset-0 z-50 backdrop-blur-sm bg-black/75 flex flex-col items-center justify-center p-16"
  >
    <button class="absolute z-0 inset-0" @click="$emit('close')" />
    <button class="absolute z-10 top-0 right-0 m-6" @click="$emit('close')">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6 text-white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>

    <Swiper
      v-if="thumbsSwiper"
      :loop="true"
      :space-between="10"
      :modules="[FreeMode, Navigation, Thumbs]"
      :slides-per-view="1"
      class="w-full h-full mx-auto"
    >
      <SwiperSlide v-for="(image, imageIndex) in images" :key="imageIndex">
        <VoixMedia :field="image" class="object-cover w-full h-full" />
      </SwiperSlide>
    </Swiper>

    <Swiper
      :loop="true"
      :space-between="10"
      :slides-per-view="3"
      :free-mode="false"
      :watch-slides-progress="true"
      :modules="[FreeMode, Navigation, Thumbs]"
      class="w-full mt-8"
      @swiper="setThumbsSwiper"
    >
      <SwiperSlide v-for="(image, imageIndex) in images" :key="imageIndex">
        <VoixMedia :field="image" class="h-[150px] w-full object-cover cursor-pointer" breakpoint="default" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>
